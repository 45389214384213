import React from 'react'
import styled from 'styled-components'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import FAIcon from '../Shared/FAIcon'
import { getEnv } from '../../utils/env'
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'

const MySolvyRedirect = () => {
  return (
    <Link
      href={`${getEnv('MYSOLVY_API_URL')}/my_tickets/?is_open=true`}
      target="_blank"
    >
      <MainListItem>
        <ListItemIcon>
          <FAIcon icon={faScrewdriverWrench} />
        </ListItemIcon>

        <ListItemText primary={'My Solvy'} />
      </MainListItem>
    </Link>
  )
}

const MainListItem = styled(ListItemButton)`
  min-height: 4rem;
`
const Link = styled.a`
  text-decoration: none;
  color: ${themeColors.black};
`

export default MySolvyRedirect
