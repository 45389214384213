import { Grid } from '@mui/material'
import styled from 'styled-components'
import {
  TextBody1Bold,
  TextBody1Medium,
  Title,
  TextBody2,
  TextBody2Bold,
} from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { IOrigoCard } from '../../graphql/hid/getOrigoAccount'
import { themeColors } from '../../const/colors'
import BaseLink from '../Shared/BaseLink'
import { getEnv } from '../../utils/env'
import { Roles } from '../../const/permissions'
import { checkRole } from '../../utils/checkRole'
import { useAppSelector } from '../../redux/store'
import {
  handleStatus,
  getStatusColor,
  handleStatusDescription,
} from '../../utils/handleMobileCardStatus'
import { Tooltip, Alert } from '@mui/material'

const MobileCard = ({ card }: { card: IOrigoCard }) => {
  const { t } = useTranslation(['hid'])
  const { roles } = useAppSelector(state => state.user)
  const {
    status,
    current,
    cardNumber,
    privilegeRequested,
    accessGranted,
    invitationCode,
    deviceId,
    origoEndpoint,
    mysolvyTicketId,
    mysolvyTicketNr,
    mysolvyTicketStatus,
  } = card

  return (
    <>
      <Row>
        <Text>{`${t('card_number')}: `}</Text>
        <TextBold>{cardNumber}</TextBold>
        {!current && (
          <TitleStyled color={themeColors.error}>{` ${t(
            'inactive',
          )} `}</TitleStyled>
        )}
      </Row>
      <Row>
        <Text>{'Status'}: </Text>
        <Tooltip
          title={`${handleStatusDescription(status, accessGranted)}`}
          placement="right"
          arrow
        >
          <span>
            <TextBold
              color={getStatusColor(status, accessGranted)}
              style={{ cursor: 'pointer' }}
            >
              {handleStatus(status, accessGranted)}
            </TextBold>
          </span>
        </Tooltip>
      </Row>

      {!!mysolvyTicketNr && (
        <Row>
          <Text>{t('my_solvy_task_number')}: </Text>
          <TextBold>{String(mysolvyTicketNr)}</TextBold>
        </Row>
      )}
      {!mysolvyTicketNr && (
        <AlertStyled severity="error">{t('no_my_solvy_number')}</AlertStyled>
      )}

      {mysolvyTicketId && (
        <Row>
          <ButtonWrapper>
            <BaseLink
              path={`${getEnv(
                'MYSOLVY_API_URL',
              )}/ticket_details/${mysolvyTicketId}/`}
              text={t('mysolvy_task_link')}
            />
          </ButtonWrapper>
        </Row>
      )}
      {checkRole(roles, [Roles.developer]) && (
        <DevInfo>
          <TextBody2Bold>{'DEV info'}</TextBody2Bold>
          <Row>
            <TextBody2>{t('active')}: </TextBody2>
            <TextBody2Bold>{String(current)}</TextBody2Bold>
          </Row>
          <Row>
            <TextBody2>{t('privileges_requested')}: </TextBody2>
            <TextBody2>{String(privilegeRequested)}</TextBody2>
          </Row>
          <Row>
            <TextBody2>{t('access_granted')}: </TextBody2>
            <TextBody2Bold>{String(accessGranted)}</TextBody2Bold>
          </Row>
          <Row>
            <TextBody2>{'Invitation Code'}: </TextBody2>
            <TextBody2Bold>
              {invitationCode ? invitationCode : 'Brak'}
            </TextBody2Bold>
          </Row>
          <Row>
            <TextBody2>{'Device ID'}: </TextBody2>
            <TextBody2>{deviceId ? deviceId : '-'}</TextBody2>
          </Row>
          <Row>
            <TextBody2>{t('device')}: </TextBody2>
            <TextBody2>
              {origoEndpoint
                ? `${origoEndpoint?.model}, os: ${origoEndpoint?.os}`
                : '-'}
            </TextBody2>
          </Row>
          <Row>
            <TextBody2>{'ID zgłoszenia MySolvy'}: </TextBody2>
            <TextBody2>{String(mysolvyTicketId)}</TextBody2>
          </Row>
          <Row>
            <TextBody2>{'Status zgłoszenia'}: </TextBody2>
            <TextBody2>{String(mysolvyTicketStatus)}</TextBody2>
          </Row>
        </DevInfo>
      )}
    </>
  )
}

export default MobileCard
const Text = styled(TextBody1Medium)`
  display: inline;
`
const TextBold = styled(TextBody1Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px 0;
`
const TitleStyled = styled(Title)`
  margin-bottom: 16px;
`
const ButtonWrapper = styled.div`
  padding: 16px 0;
`
const DevInfo = styled.div`
  background-color: ${themeColors.paleGray};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
const AlertStyled = styled(Alert)`
  margin-top: 16px;
`
